<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/dd-logo.svg')"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-xl font-weight-semibold text--primary mb-2 text-center">
            {{ $store.state.appName }}
          </p>
          <p class="mb-2 text-center">
            Please enter one time password
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="otp"
              outlined
              :type="isOtpVisible ? 'text' : 'password'"
              label="One Time Password"
              placeholder="············"
              :append-icon="isOtpVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :error-messages="formErrorFields.otpErrorMessage[0]"
              @click:append="isOtpVisible = !isOtpVisible"
              @keydown="formError().remove('otp', formErrorFields)"
              @keypress.enter="proceedWasClicked"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              class="mt-6"
              :loading="loading"
              @click="proceedWasClicked"
            >
              Proceed
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          &nbsp;
        </v-card-actions>
      </v-card>
    </div>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      @close="message=''"
    >
    </snackbar>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from 'axios'
import Cookies from 'universal-cookie'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, watchEffect } from '@vue/composition-api'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'
import { useRouter } from '@/utils'
import fnx from '@/functions/fn'

export default {
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'LOGIN',
    }
  },
  setup() {
    const { router } = useRouter()
    const message = ref('')
    const isOtpVisible = ref(false)
    const otp = ref('')
    const formErrorFields = ref({
      otpErrorMessage: '',
    })

    const loading = ref(false)

    watchEffect(() => {
      if (localStorage.getItem('userId') === undefined) {
        router.replace({ path: '/auth/login' })
      }
    })

    const proceedWasClicked = async () => {
      loading.value = true
      try {
        const response = await axios.post(`${process.env.VUE_APP_URI}/api/auth/payroll/confirm-otp`, {
          userId: localStorage.getItem('userId'),
          otp: otp.value,
        })
        loading.value = false

        let data = response
        data = data.data

        const cookies = new Cookies()

        cookies.set('access_token', data.access_token, {
          path: '/',
          sameSite: true,
        })

        cookies.set('expires_in', data.expires_in, {
          path: '/',
          sameSite: true,
        })

        cookies.set('user', fnx.base64_encode(data.user), {
          path: '/',
          sameSite: true,
        })

        router.replace({ path: '/dashboard' })
      } catch (error) {
        loading.value = false
        if (error.response.status === 422) {
          formErrorFields.value.otpErrorMessage = error.response.data.errors.otp
        } else {
          message.value = error.response.data.message
        }
      }
    }

    return {
      isOtpVisible,
      otp,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      proceedWasClicked,
      formErrorFields,
      formError,
      message,
      loading,
    }
  },
  components: {
    snackbar,
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
